<template>
  <v-container fluid class="pa-0">

    <v-row no-gutters>
      <v-col cols="9">
        <GeneralFinance/>
      </v-col>
      <v-col cols="3">
        <v-navigation-drawer width="100%" right permanent elevation="2" class="grey lighten-4">
          <v-card>
            <v-toolbar class="primary--text">
              <v-toolbar-title>Tableau des finances</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="$store.dispatch('finance/getExport')" color="primary"><v-icon>mdi-table</v-icon>Exporter</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row align-content="center" justify="center" class="py-2">
                <v-btn v-if="isResponsableOrAdmin || hasPermission('add_entry')" @click="openNewEntryDrawer"
                       color="primary">Nouvelle entrée
                </v-btn>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row class="my-3" justify="center">
                <h4 class="grey--text">
                  <v-icon>mdi-filter-outline</v-icon>
                  FILTRER LES DÉPENSES
                </h4>
              </v-row>
              <v-row justify="center">
                <SiteTreeSelector nomargin v-model="filter_site_tree"/>
              </v-row>
              <v-row justify="center">
                <v-checkbox v-model="displayContract" label="Afficher les entrées sous contrat"></v-checkbox>
              </v-row>
              <v-text-field class="mx-2" v-model="filter_id" @input="displayContract = true"
                            type="number" label="Identifiant de l'entrée financière"></v-text-field>
              <v-select class="mx-2" v-model="filter_type" @change="displayContractWithTypeChange"
                        label="Type de dépense" item-text="name" item-value="name" :items="types_depense"></v-select>
              <v-select class="mx-2" v-model="filter_depense" label="Catégorie de dépense" item-text="name"
                        item-value="value" :items="statut_depense"></v-select>
              <v-autocomplete class="mx-2" v-model="filter_prestataire" label="Prestataire" item-text="raison_sociale"
                              item-value="id" :items="prestataires"></v-autocomplete>
              <v-autocomplete class="mx-2" v-model="filter_contract" label="Contrat" item-text="name"
                              item-value="id" :items="contracts"></v-autocomplete>
              <v-row class="my-2" justify="center">
                <h4 class="grey--text text-uppercase">
                  <v-icon>mdi-calendar</v-icon>
                  Période sélectionnée
                </h4>
              </v-row>
              <v-row justify="space-between">
                <v-btn text color="primary" @click="changeMonth('minus')" icon>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn text color="primary" class="text-uppercase">{{ monthDisplay }}</v-btn>
                <v-btn text color="primary" @click="changeMonth('plus')" icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-select label="Mois" v-model="filter_month" item-text="name" item-value="value"
                            :items="months_select"></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="filter_year" label="Année" :items="active_years"></v-select>
                </v-col>
              </v-row>

              <v-row justify="center" no-gutters>
                <v-btn color="red" text @click="resetFilters">
                  <v-icon>mdi-trash-can</v-icon>
                  Retirer les filtres
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import {FinanceMixin} from "@/mixins/finance/FinanceMixin";
import GeneralFinance from "@/views/GeneralFinance";
import SiteTreeSelector from "@/views/SiteTreeSelector";
import {convertToSelect} from "@/util/core";

const MONTHS = {
  "0": "Janvier",
  "1": "Février",
  "2": "Mars",
  "3": "Avril",
  "4": "Mai",
  "5": "Juin",
  "6": "Juillet",
  "7": "Août",
  "8": "Septembre",
  "9": "Octobre",
  "10": "Novembre",
  "11": "Décembre",
}
export default {
  name: "Finances",
  mixins: [AuthMixin, DateUtilMixin, DrawerMixin, FinanceMixin],
  components: {SiteTreeSelector, GeneralFinance},
  async mounted() {
    this.resetFilters();
    if (this.finance_loading_status === "INITIAL" || this.finance_loading_status === "ERROR") {
      await this.$store.dispatch("finance/fetchEntries");
    }
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
    if (this.$store.getters["prestataires/allPrestataires"].length === 0) {
      await this.$store.dispatch("prestataires/fetchPrestataires");
    }
    if (this.$store.getters["contracts/allContracts"].length === 0) {
      await this.$store.dispatch("contracts/fetchContracts");
    }
    let type = this.$route.query.type;
    if (type) {
      this.filter_type = type;
      this.displayContractWithTypeChange();
    }
    let statut = this.$route.query.statut;
    if (statut) {
      if (statut === "interventions") {
        this.types_depense = "realise";
        this.displayContract = false;
      } else if (statut === "contrats") {
        this.types_depense = "realise";
        this.filter_type = "Dépense sous contrat";
        this.displayContract = true;
      } else {
        this.filter_depense = statut;
        this.displayContract = true;
      }
    }
    let year = this.$route.query.year;
    if (year) {
      this.filter_year = parseInt(year);
    }
    let month = this.$route.query.month;
    if (month && month !== "null") {
      this.filter_month = month;
    }
    let prestataire = this.$route.query.prestataire;
    if (prestataire) {
      this.filter_prestataire = parseInt(prestataire);
    }
    let contract = this.$route.query.contract;
    if (contract) {
      this.filter_contract = parseInt(contract);
      this.displayContract = true;
    }
    let sites = this.$route.query.sites;
    if (sites) {
      this.filter_site_tree = sites.split(",").map((s) => parseInt(s));
    }
    let contract_name = this.$route.query.contract_name;
    if (contract_name) {
      let contract = this.$store.getters["contracts/getContractByName"](contract_name);
      if (contract !== undefined) {
        this.filter_contract = contract.id;
        this.displayContract = true;
      }
    }
    let entryId = this.$route.query.id;
    if(entryId) {
      this.$store.commit("finance/filter/setFilterId", parseInt(entryId));
    }
  },
  computed: {
    months_select() {
      return convertToSelect(MONTHS);
    },
    monthDisplay() {
      if (this.filter_month !== "" && this.filter_year !== null) {
        return MONTHS[this.filter_month] + " " + this.filter_year.toString();
      } else if (this.filter_month !== "" && this.filter_year === null) {
        return MONTHS[this.filter_month];
      } else if (this.filter_month === "" && this.filter_year !== null) {
        return this.filter_year.toString();
      } else {
        return "Toutes périodes confondues";
      }
    },
  },
  methods: {
    changeMonth(direction) {
      if (this.filter_month === "" && this.filter_year === null) {
        return;
      } else if (this.filter_month !== "" && this.filter_year === null) {
        if (direction === "minus") {
          if (parseInt(this.filter_month) === 0) {
            this.filter_month = "12";
          } else {
            this.filter_month = (parseInt(this.filter_month) - 1).toString();
          }
        } else {
          if (parseInt(this.filter_month) === 11) {
            this.filter_month = "0";
          } else {
            this.filter_month = (parseInt(this.filter_month) + 1).toString();
          }
        }
      } else if (this.filter_month === "" && this.filter_year !== null) {
        if (direction === "minus") {
          this.filter_year = this.filter_year - 1;
        } else {
          this.filter_year = this.filter_year + 1;
        }
      } else {
        if (direction === "minus") {
          if (parseInt(this.filter_month) === 0) {
            this.filter_month = "12";
            this.filter_year = this.filter_year - 1;
          } else {
            this.filter_month = (parseInt(this.filter_month) - 1).toString();
          }
        } else {
          if (parseInt(this.filter_month) === 11) {
            this.filter_month = "0";
            this.filter_year = this.filter_year + 1;
          } else {
            this.filter_month = (parseInt(this.filter_month) + 1).toString();
          }
        }
      }
    }
  }
}
</script>

