import EntryForm from "@/components/finance/EntryForm";

export const FinanceMixin = {
    data() {
        return {
            deleteDialog: false,
            entry_to_delete: null,
        }
    },
    computed: {
        finance_loading_status() {
          return this.$store.getters["finance/getLoadingStatus"];
        },
        types_depense() {
            return [
                {"name": ""},
                {"name": "Budget"},
                {"name": "Dépense ponctuelle"},
                {"name": "Dépense sous contrat"},
                {"name": "Dépense sous contrat supplémentaire"},
                {"name": "Casse"},
            ];
        },
        active_years() {
            return this.$store.getters["finance/stats/active_years"];
        },
        statut_depense() {
            return [
                {value: "", name: "Toutes les dépenses"},
                {value: "previsionnel", name: "Prévisionnel"},
                {value: "realise", name: "Réalisé"}
            ]
        },
        entries() {
            return this.$store.getters["finance/allEntries"];
        },
        displayContract: {
            get() {
                return this.$store.getters['finance/filter/getDisplayContract'];
            },
            set(value) {
                this.$store.commit("finance/filter/setDisplayContract", value);
            }
        },
        filter_type: {
            get() {
                return this.$store.getters['finance/filter/getFilterType'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterType", value);
            }
        },
        filter_id: {
            get() {
                return this.$store.getters['finance/filter/getFilterId'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterId", value);
            }
        },
        filter_site_tree: {
            get() {
                return this.$store.getters['finance/filter/getFilterSiteTree'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterSiteTree", value);
            }
        },
        filter_depense: {
            get() {
                return this.$store.getters['finance/filter/getFilterDepense'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterDepense", value);
            }
        },
        filter_year: {
            get() {
                return this.$store.getters['finance/filter/getFilterYear'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterYear", value);
            }
        },
        filter_month: {
            get() {
                return this.$store.getters['finance/filter/getFilterMonth'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterMonth", value);
            }
        },
        filter_prestataire: {
            get() {
                return this.$store.getters['finance/filter/getFilterPrestataire'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterPrestataire", value);
            }
        },
        filter_contract: {
            get() {
                return this.$store.getters['finance/filter/getFilterContract'];
            },
            set(value) {
                this.$store.commit("finance/filter/setFilterContract", value);
            }
        },
        prestataires() {
            return this.$store.getters["prestataires/allPrestataires"];
        },
        contracts() {
            return this.$store.getters["contracts/allContracts"];
        }
    },
    methods: {
        editEntry(entry) {
            let found_entry = this.entries.find(item => entry.id === item.id);
            this.openDrawerDialog(EntryForm, {key: entry.id, entryToUpdate: found_entry, operation: "update"});
        },
        askDeleteEntry(entry) {
            this.entry_to_delete = entry;
            this.deleteDialog = true;
        },
        async deleteEntry() {
            await this.$store.dispatch("finance/deleteEntry", this.entry_to_delete);
            await this.$store.dispatch("messages/announceSuccess", "Entrée financière supprimée.");
            this.entry_to_delete = null;
            this.deleteDialog = false;
        },
        openNewEntryDrawer() {
            this.openDrawerDialog(EntryForm, {key: 0, entryToUpdate: null, operation: "create"});
        },
        displayContractWithTypeChange() {
            if (this.filter_type === 'Dépense sous contrat') {
                this.displayContract = true;
            }
        },
        resetFilters() {
            this.filter_depense = "";
            this.displayContract = false;
            this.filter_type = "";
            this.filter_year = null;
            this.filter_month = "";
            this.filter_id = "";
            this.filter_site_tree = [];
            this.filter_prestataire = null;
            this.filter_contract = null;
        }
    }
}