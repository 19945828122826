<template>
  <div>
    <v-sheet class="ma-2" color="white">
      <v-row>
        <v-col cols="12" md="6">
          <v-card color="orange" class="ma-4 pa-3 text-center">
            <h2 class="white--text">
              Total dépensé en interventions<br>
              {{ total_interventions }} €
            </h2>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card color="purple" v-if="displayContract" class="ma-4 pa-3 text-center">
            <h2 class="white--text">
              Total dépensé en contrats<br>
              {{ total_contrats }} €
            </h2>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <v-data-table loading-text="Chargement des entrées financières en cours..." :loading="!entriesLoaded" class="ma-2"
                  dense :items="data_entries" item-key="id" :headers="headers">
      <template v-slot:item.asset="{ item }">
        <router-link v-if="item.asset !== null" :to="'/assets/' + item.asset">Voir l'équipement</router-link>
      </template>
      <template v-slot:item.contract="{ item }">
        <router-link v-if="item.contract !== null" :to="'/contracts/' + item.contract">{{
            item.contract_name
          }}
        </router-link>
      </template>
      <template v-slot:item.intervention="{ item }">
        <router-link v-if="item.intervention !== null" :to="'/interventions/' + item.intervention">Voir l'intervention
        </router-link>
      </template>
      <template v-slot:item.prestataire_raison_sociale="{ item }">
      <span v-if="item.prestataire_id"><router-link
          :to="'/prestataires/' + item.prestataire_id">{{
          item.prestataire_raison_sociale
        }}</router-link></span>
      </template>
      <template v-slot:item.date_gestion="{ item }">
        <span v-if="item.date_gestion !== null">{{ getFormattedDate(item.date_gestion) }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isResponsableOrAdmin || hasPermission('change_entry')"
                small
                class="mr-2"
                @click="editEntry(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            class="mr-2"
            @click="askDeleteEntry(item)"
        >
          mdi-trash-can-outline
        </v-icon>
        <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_entry')" width="500">
          <v-card>
            <v-card-title class="headline red lighten-1 white--text">
              Supprimer l'entrée financière ?
            </v-card-title>
            <v-card-text class="body-1 mt-2">
              Confirmer la suppression de l'entrée financière ?
            </v-card-text>
            <v-card-actions>
              <v-btn @click="deleteEntry" text color="red">Supprimer l'entrée financière</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {FinanceMixin} from "@/mixins/finance/FinanceMixin";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

export default {
  name: 'GeneralFinance',
  mixins: [AuthMixin, FinanceMixin, DateUtilMixin, DrawerMixin],
  data() {
    return {
      headers: [
        {'text': "ID", 'value': 'id'},
        {"text": "Référence", "value": "reference"},
        {"text": "Date de gestion", "value": "date_gestion"},
        {"text": "Type de dépense", "value": "type_depense"},
        {"text": "Prévisionnel", "value": "previsionnel"},
        {"text": "Réalisé", "value": "realise"},
        {"text": "Date de facture", "value": "date_facture"},
        {"text": "Référence de la facture", "value": "reference_facture"},
        {"text": "Équipement", "value": "asset"},
        {"text": "Intervention", "value": "intervention"},
        {"text": "Prestataire", "value": "prestataire_raison_sociale"},
        {"text": "Contrat", "value": "contract"},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  computed: {
    total_interventions() {
      return this.data_entries.filter(entry => entry.intervention !== null).map((entry => parseFloat(entry.realise))).reduce((a, b) => a + b, 0).toFixed(2);
    },
    total_contrats() {
      return this.data_entries.filter(entry => entry.contrat !== null && entry.intervention === null).map((entry => parseFloat(entry.realise))).reduce((a, b) => a + b, 0).toFixed(2);
    },
    entriesLoaded() {
      return this.finance_loading_status === "LOADED";
    },
    data_entries() {
      return this.$store.getters['finance/filter/getFilteredEntries'];
    },
  }
}
</script>
